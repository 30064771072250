<template>
    <div class="container_con">
        
        <div class="new_search_box flex">
          <div style="margin-top:0px">
              <span class="mr_20">
                时间：
                <el-date-picker
                  v-model="queryParams.time"
                  type="daterange"  value-format="YYYY-MM-DD" 
                  placeholder="请选择时间"
                  range-separator="至" style="width: 300px;"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </span>
            <el-button type="primary" @click="handleQuery()">搜索</el-button>
            <el-button @click="resetQuery()">重置</el-button>
          </div>
        </div>
        <div class="search">
            <div>
               <el-button type="primary" @click="$router.push('/addTemperature')" v-if="have_auth('/entrepot/add')">+ 新增</el-button>
               <el-button type="primary" icon="el-icon-upload2" :loading="is_export" @click="try_export" v-if="have_auth('/entrepot/export')">导出</el-button>
            </div>
        </div>
        <div style="margin-top:20px">
            <el-table :data="list" v-loading="loading" style="width: 100%" >
                <el-table-column prop="record_date" label="日期" />
                <el-table-column label="适应温度范围">
                    <template v-slot="scope">
                      {{scope.row.temp_lower+'℃-'+scope.row.temp_upper+'℃'}}
                    </template>
                </el-table-column>
                <el-table-column label="适应湿度范围">
                    <template v-slot="scope">
                      {{scope.row.humidity_lower+'%-'+scope.row.humidity_upper+'%'}}
                    </template>
                </el-table-column>
                <el-table-column prop="temp_am" label="上午温度" />
                <el-table-column prop="temp_pm" label="下午温度" />
                <el-table-column label="上午湿度">
                    <template v-slot="scope">
                      {{scope.row.humidity_am+'%'}}
                    </template>
                </el-table-column>
                <el-table-column label="下午湿度">
                    <template v-slot="scope">
                      {{scope.row.humidity_pm+'%'}}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="record_date" label="上午超标起始时间" width="150" /> -->
                <el-table-column fixed="right" label="操作" width="150">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="handleDetail(scope.row)" v-if="have_auth('/entrepot/view')">查看</el-button>
                        <el-button type="text" size="small" @click="handleUpdate(scope.row)" v-if="have_auth('/entrepot/edit')">编辑</el-button>
                        <el-button type="text" size="small" @click="handleDelete(scope.row)" v-if="have_auth('/entrepot/del')">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "department",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    time:''
                },
                total:0,
                list:[],
                is_export:false,
            };
        },
        created() {
            this.queryParams=window['entrepot_temperature'] || {
              page: 1,
              size: 10,
              product_no:''
            }
        },
        methods: {
            save_filter(){
                window['entrepot_temperature']=this.queryParams
            },
            resetQuery(){
                this.queryParams= {
                    page: 1,
                    size: 10,
                    time:''
                }
                this.getList()
            },
            try_export(){
                if(this.is_export){return false;}
                this.is_export = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/WarehouseTempHumidity/export", _data).then((res) => {
                    if (res.status == 200) {
                          var a = document.createElement('a');
                          let name=res.data.file.split('/')
                          var fileName = name[name.length-1];
                          a.download = fileName;
                          a.href = this.$http+'/'+res.data.file;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_export = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
            },
            // 查看详情
            handleDetail(row){
                this.$router.push('/temperatureDetail?id='+row.id)
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
              this.$router.push('/addTemperature?id='+row.id)
            },
            /** 删除按钮操作 */
            handleDelete(row) {
              const _this=this;
              const ids = row.id || this.ids;
              this.$confirm('是否确认删除?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  _this.$httpGet("/backend/WarehouseTempHumidity/del", {ids:ids}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/WarehouseTempHumidity/index", _data).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>
<style lang="scss" scoped></style>
