<template>
    <div class="container_con">
        <div class="new_search_box flex">
          <div style="margin-top:0px">
              <span class="mr_20">
                编号：
                <el-input icon="el-icon-search" v-model="queryParams.keywords" @keyup.enter.native="handleQuery" placeholder="请输入编号或品名" clearable
                    style="width:300px;margin-right:10px"></el-input>
              </span>
			  <span class="mr_20">
			    生产厂家：
			    <el-input icon="el-icon-search" v-model="queryParams.manufacturer" @keyup.enter.native="handleQuery" placeholder="请输入生产厂家" clearable
			        style="width:300px;margin-right:10px"></el-input>
			  </span>
            <el-button type="primary" @click="handleQuery()">搜索</el-button>
            <el-button @click="resetQuery()">重置</el-button>
          </div>
        </div>
        <div class="search">
            <div>
               <el-button type="primary" icon="el-icon-upload2" :loading="is_export" @click="try_export" v-if="have_auth('/entrepot/export')">导出</el-button>
            </div>
        </div>
        <div style="margin-top:20px">
            <el-table :data="list" v-loading="loading" style="width: 100%">
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                
                <el-table-column prop="product_no" label="产品编号" />
				<el-table-column prop="product_name" label="品名" />
				<el-table-column prop="manufacturer" label="生产厂家" />
                <el-table-column prop="stock" label="库存数量" />
                <el-table-column prop="pack_unit_name" label="包装单位" />
                <el-table-column prop="batch_number" label="批号" />
				<el-table-column label="有效期">
				    <template v-slot="scope">
				        {{scope.row.valid_until || '长效期'}}
				    </template>
				</el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="handleDetail(scope.row)" v-if="have_auth('/entrepot/view')">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "validity",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    keywords: '',
					manufacturer:''
                },
                total:0,
                list:[],
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                is_export:false
            };
        },
        created() {
            this.queryParams=window['entrepot_product'] || {
              page: 1,
              size: 10,
              keywords:'',
			  manufacturer:''
            }
        },
        methods: {
            save_filter(){
                window['entrepot_product']=this.queryParams
            },
            resetQuery(){
                this.queryParams= {
                    page: 1,
                    size: 10,
                    keywords:'',
					manufacturer:''
                }
                this.getList()
            },
            try_export(){
                if(this.is_export){return false;}
                this.is_export = true;
                this.$httpGet("/backend/ProductEarlyWarning/productNotSaleExport", this.queryParams).then((res) => {
                    if (res.status == 200) {
                          var a = document.createElement('a');
                          let name=res.data.file.split('/')
                          var fileName = name[name.length-1];
                          a.download = fileName;
                          a.href = this.$http+'/'+res.data.file;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_export = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
              this.ids = selection.map(item => item.id);
              this.single = selection.length != 1;
              this.multiple = !selection.length;
            },
            // 查看详情
            handleDetail(row){
                this.$router.push('/productDetail?id='+row.product_id)
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                this.$httpGet("/backend/ProductEarlyWarning/productNotSale", this.queryParams).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>
<style lang="scss" scoped></style>

