<template>
    <div class="container_con">
        
        <div class="new_search_box flex">
          <div style="margin-top:0px">
              <span class="mr_20">
                关键字：
                <el-input
                  v-model="queryParams.title"
                  placeholder="请输入客户名称/编码/助记码"
                  style="width:240px"
                />
              </span>
              <span class="mr_20">
                预警天数：
                <el-input
                  v-model="queryParams.day"
                  placeholder="请输入预警天数"
                  style="width:240px"
                />
              </span>
            <el-button type="primary" @click="handleQuery()">搜索</el-button>
            <el-button @click="resetQuery()">重置</el-button>
          </div>
        </div>
        <div class="search">
            <div>
                <el-button type="primary" icon="el-icon-upload2" :loading="is_export" @click="try_export" v-if="have_auth('/entrepot/export')">导出</el-button>
            </div>
        </div>
        <div style="margin-top:20px">
            <el-table :data="list" v-loading="loading" style="width: 100%">
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                <el-table-column prop="merchant_no" label="客户编号" />
                <el-table-column prop="merchant_name" label="客户名称" />
                <el-table-column prop="bl_name" label="营业执照" />
                <el-table-column label="营业执照有效期">
                    <template v-slot="scope">
                        {{scope.row.bl_operating_period || '长效期'}}
                    </template>
                </el-table-column>
                <el-table-column prop="mi_institution_name" label="医疗机构执业"   />
                <el-table-column prop="mi_valid_until" label="医疗机构执业有效期"  />
                <el-table-column prop="mebl_company_name" label="医疗器械经营"   />
                <el-table-column prop="mebl_valid_until" label="医疗器械经营有效期"  />
                <!-- <el-table-column prop="smdorc_company_name" label="第二类医疗器械" /> -->
                <el-table-column label="是否过期">
                    <template v-slot="scope">
                        {{scope.row.is_overdue==1?'是':'否'}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="handleDetail(scope.row)" v-if="have_auth('/entrepot/view')">查看</el-button>
                        <el-button type="text" size="small" @click="handleBack(scope.row)" v-if="scope.row.is_overdue==1 && have_auth('/entrepot/open')"><i :class="scope.row.is_temporary==1?'el-icon-check':'el-icon-close'"></i>临时开单</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "validity",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    title: '',
                    day:''
                },
                total:0,
                list:[],
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                is_export:false
            };
        },
        created() {
            this.queryParams=window['entrepot_certificate'] || {
              page: 1,
              size: 10,
              title: '',
              day:''
            }
        },
        methods: {
            save_filter(){
                window['entrepot_certificate']=this.queryParams
            },
            /** 临时开单按钮操作 */
            handleBack(row) {
              const _this=this;
              const ids = row.id || this.ids;
              let type=row.is_temporary;
              this.$confirm('是否确认'+(type==1?'不允许':'允许')+'临时开单?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  _this.$httpGet("/backend/Merchant/temporary", {id:ids,type:type==1?'0':'1'}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            resetQuery(){
                this.queryParams= {
                    page: 1,
                    size: 10,
                    title: '',
                    day:''
                }
                this.getList()
            },
            try_export(){
                if(this.is_export){return false;}
                this.is_export = true;
                this.$httpGet("/backend/MerchantEarlyWarning/export", this.queryParams).then((res) => {
                    if (res.status == 200) {
                          var a = document.createElement('a');
                          let name=res.data.file.split('/')
                          var fileName = name[name.length-1];
                          a.download = fileName;
                          a.href = this.$http+'/'+res.data.file;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_export = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
              this.ids = selection.map(item => item.id);
              this.single = selection.length != 1;
              this.multiple = !selection.length;
            },
            // 查看详情
            handleDetail(row){
                this.$router.push('/customerDetail?id='+row.id)
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                this.$httpGet("/backend/MerchantEarlyWarning/index", this.queryParams).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>
<style lang="scss" scoped></style>
