<template>
  <div class="container_con">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="仓库温湿度登记表" name="1">
          <Temperature ref="model1" />
        </el-tab-pane>
        <el-tab-pane label="近效期产品预警表" name="2">
          <Validity ref="model2" />
        </el-tab-pane>
        <el-tab-pane label="近效期产品注册证预警表" name="3">
          <Register ref="model3" />
        </el-tab-pane>
        <el-tab-pane label="近期客户证件预警表" name="4">
          <Certificate ref="model4" />
        </el-tab-pane>
        <el-tab-pane label="产品库存预警表" name="5">
          <Repertory  ref="model5" />
        </el-tab-pane>
		<el-tab-pane label="产品预设天数无销售预警表" name="6">
		  <Product  ref="model6" />
		</el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Temperature from "@/components/entrepot/temperature";
import Register from "@/components/entrepot/register";
import Validity from "@/components/entrepot/validity";
import Certificate from "@/components/entrepot/certificate";
import Repertory from "@/components/entrepot/repertory";
import Product from "@/components/entrepot/product";
export default {
  name: "entrepot",
  components: {
    Temperature,
    Register,
    Validity,
    Certificate,
    Repertory,
	Product
  },
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    this.activeName = sessionStorage.getItem("active")
      ? sessionStorage.getItem("active")
      : this.activeName;
    this.$nextTick(()=>{
        this.$refs['model'+this.activeName].getList()
    })  
    this.old_active=this.activeName;
  },
  watch: {
    $route(to,form) {
        this.$refs['model'+this.old_active].save_filter();
    },
  },
  methods: {
    handleClick() {
      sessionStorage.setItem("active", this.activeName);
      this.$refs['model'+this.activeName].getList()
      this.$refs['model'+this.old_active].save_filter();
      this.old_active=this.activeName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
